import {
  useEffect,
  useState,
} from 'react';

import {
  GoogleMap,
  LoadScript,
  Marker,
} from '@react-google-maps/api';

const CitiesAreas = ({ data }) => {
  // console.log("sdjncmoasdvmioas",data.weather);
  const locationName = data.name;
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [name, setName] = useState("");
  useEffect(() => {
    const getLocationCoordinates = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            locationName
          )}&key=AIzaSyASdpNTLU5ZEqnl9eG0yP3n8GHmXGR0igM`
        );
        // console.log(response);
        const data = await response.json();
        if (data.status === "OK" && data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          const name = data.results[0].formatted_address;
          // console.log("name", name);
          setCoordinates({ lat, lng });
          setName({});
          // console.log(coordinates.lat,coordinates.lng);
          const urlW = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=5c1d3b3059e6fab9ef0493c4d30eb82e&units=metric`;
          const responseWeather = await fetch(urlW);
          const dataW = await responseWeather.json();
          // console.log("dataW",dataW);
        } else {
          console.error("No coordinates found for the location:", locationName);
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    };

    getLocationCoordinates();
  }, [locationName]);
  // console.log(coordinates);
  // const position = coordinates.lat  coordinates.lng
  const lat = coordinates.lat
  const lng = coordinates.lng

  // const coordinates = {lat: 53.54992, lng: 10.00678}
  const mapContainerStyle = {
    width: '100%',
    height: '621px',
  };


  const retroMapOptions = {
    styles: [
      {
        featureType: 'all',
        elementType: 'geometry',
        stylers: [
          {
            color: '#FFFBDA' // Use the desired color here
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f1e6"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#fdfcf8"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f8c967"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#e9bc62"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e98d58"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#db8555"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#806b63"
          }
        ]
      },
       {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#523735"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f1e6"
      }
    ]
  },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#F6D776"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#F6D776"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#F6D776"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#F6D776"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#F6D776"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#F6D776"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#8f7d77"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#ebe3cd"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#b9d3c2"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#92998d"
          }
        ]
      }
    ]
};

  


  const coordinat = {lat:lat,lng:lng}



  // useEffect(()=>{
  //   const abc  = async()=>{
  //   try{
  //     console.log(coordinates.lat,coordinates.lng);
  //       const urlW = `https://api.openweathermap.org/data/2.5/weather?lat=${coordinates.lat}&lon=${coordinates.lng}&appid=5c1d3b3059e6fab9ef0493c4d30eb82e&units=metric`;
  //       const responseWeather = await fetch(urlW);
  //       const dataW = await responseWeather.json();
  //       console.log("dataW",dataW);
      
  //   }catch(error){
  //     console.error("Error fetching coordinates:", error);
  //   }
  // }
  //   abc()
  // },[])
  return (
    <div className="py-5 areas">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center">
          <div>
            <h5 className=" fs_30 ff_heebo fw-medium mb-0">
              Few cities / areas
            </h5>
            <p className="fs_17 ff_heebo mt-2">{data.fewCities}</p>
          </div>
          {/* <button className=" bg_dark_brown px-5 py-2 rounded-2 text-white ff_heebo border-0">
            Explore
          </button> */}
        </div>
        <div className="areas_margin_bottom">
          {/* <iframe
            className="w-100 mt-4"
            src={`https://www.google.com/maps/embed/v1/view?&key=AIzaSyASdpNTLU5ZEqnl9eG0yP3n8GHmXGR0igM&&center=${coordinates.lat},${coordinates.lng}&zoom=6&maptype=satellite`}
            height="621"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title={`Map of ${name}`}
          ></iframe> */}
          <LoadScript googleMapsApiKey="AIzaSyASdpNTLU5ZEqnl9eG0yP3n8GHmXGR0igM">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={coordinat}
        gestureHandling='greedy'
        zoom={5}
        mapTypeId="roadmap"
        options={{ styles: retroMapOptions.styles}}
      >
        <Marker position={coordinat} />
      </GoogleMap>
    </LoadScript>
        </div>
      </div>
    </div>
  );
};

export default CitiesAreas;
