import React from 'react';

const PopularLocalFoods = ({data}) => {
  console.log("data=========>",data);
  
  return (
    <div className=" py-5 footer_background">
      <div className="container">
        <h4 className=" text-white fs_36 text-center fw-medium ff_heebo">
        Some popular local dishes
        </h4>
        <p className=" text-white  mt-3" dangerouslySetInnerHTML={{ __html: data.popularFoods }}>
        {/* dangerouslySetInnerHTML={{ __html: data.popularFoods }} */}
        </p>
        {/* <div className="row mt-5 justify-content-between">
          <div className="col-lg-4 col-sm-6 pe-lg-4 pt-lg-5 mt-4">
            <img
              className="w-100 "
              src={`${process.env.REACT_APP_IMAGE_URL}/${data?.multifoodImg?.[0]}`}
              alt="Somepopularlocalfoods_image1"
            />
          </div>
          <div className="col-lg-4 col-sm-6 ps-lg-4 pe-lg-4 mt-4 mt-lg-0">
            <img
              className="w-100"
              src={`${process.env.REACT_APP_IMAGE_URL}/${data?.multifoodImg?.[1]}`} 
              alt="Somepopularlocalfoods_image2"
            />
          </div>
          <div className="col-lg-4 col-sm-6 ps-lg-4 pt-lg-5 mt-4">
            <img
              className="w-100"
              src={`${process.env.REACT_APP_IMAGE_URL}/${data?.multifoodImg?.[2] || data.singleImage}`}
              alt="Somepopularlocalfoods_image3"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PopularLocalFoods;
