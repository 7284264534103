import React, { useEffect, useState } from "react";
import "./CoupleReasons.css";

import { Link } from "react-router-dom";

import couple_image from "../../assets/images/couple_image.png";

const CoupleReasons = ({ data }) => {
  const [Couple, setCouple] = useState([]);

  useEffect(() => {
    if (data && data.coupleReasons && Array.isArray(data.coupleReasons)) {
      setCouple(data.coupleReasons);
    }
  }, [data]);

  const slicedArray = Couple.slice(0, Couple.length - 1);

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupImage, setPopupImage] = useState('');

  const handleImageHover = (imageUrl) => {
    setPopupImage(imageUrl);
    setPopupVisible(true);
  };

  const handleImageLeave = () => {
    setPopupVisible(false);
  };

  return (
    <div className="pt-5 pb-0 pb-md-5">
      <div className="container">
        <h5 className="fs_30 ff_heebo fw-medium">Some native animals</h5>
        <ul className="mt-3 ps-3">
          <li>
            <p
              className="fs_17 ff_heebo fw-normal"
              dangerouslySetInnerHTML={{ __html: data.animals }}
            ></p>
          </li>
        </ul>

        <h5 className="fs_30 ff_heebo mt-5 fw-medium">
          Couple reasons to visit
        </h5>
        <div className="d-md-flex gap-5 align-items-start">
          <div>
            <p>
              <ul className="mt-3 ps-3">
                {slicedArray.map((item, index) => (
                  <li key={index}>
                    <p
                      className="fs_17 ff_heebo fw-normal"
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  </li>
                ))}
              </ul>
            </p>
            <Link to="/contact-us">
              <button className="mt-4 bg_dark_brown ff_heebo fw-medium px-5 py-2 rounded-2 border-0 text-white">
                Request more details
              </button>
            </Link>
          </div>

          <div className="row justify-content-between" style={{ width: "70%" }}>
  {/* First row with two images */}
  <div className="col-6">
    <div className="image-container">
      <img
        className="w-100 rounded-4 hover-zoom"
        src={`${process.env.REACT_APP_IMAGE_URL}${data?.multifoodImg?.[0]}`}
        alt="Somepopularlocalfoods_image1"
        onMouseEnter={() => handleImageHover(data?.multifoodImg?.[0])}
        onMouseLeave={handleImageLeave}
      />
    </div>
    <div className="image-container mt-4">
      <img
        className="w-100 rounded-4 hover-zoom"
        src={`${process.env.REACT_APP_IMAGE_URL}${data?.multifoodImg?.[1]}`}
        alt="Somepopularlocalfoods_image2"
        onMouseEnter={() => handleImageHover(data?.multifoodImg?.[1])}
        onMouseLeave={handleImageLeave}
      />
    </div>
  </div>

  {/* Second row with one image */}
  <div className="col-6 image-container align-content-center">
    <div className="col-lg-12 col-sm-12">
      <img
        className="hover-zoom rounded-4"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        src={`${process.env.REACT_APP_IMAGE_URL}${
          data?.multifoodImg?.[2] || data.singleImage
        }`}
        alt="Somepopularlocalfoods_image3"
        onMouseEnter={() => handleImageHover(data?.multifoodImg?.[2] || data.singleImage)}
        onMouseLeave={handleImageLeave}
      />
    </div>
  </div>

  {/* Popup */}
  {/* {popupVisible && (
    <div className="image-popup"  onMouseEnter={() => setPopupVisible(true)} onMouseLeave={handleImageLeave}>
      <img src={`${process.env.REACT_APP_IMAGE_URL}${popupImage}`} alt="Popup" />
    </div>
  )} */}
</div>

          {/* <img
            width={478}
            className="mt-5"
            src={couple_image}
            alt="couple_image"
          />
          <img
              className="w-100 "
              src={`${process.env.REACT_APP_IMAGE_URL}${data?.multifoodImg?.[0]}`}
              alt="Somepopularlocalfoods_image1"
            /> */}
        </div>
      </div>
    </div>
  );
};

export default CoupleReasons;
